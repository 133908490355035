export const units = ['bytes', 'KB', 'MB', 'GB'] as const;
export type UnitTypes = typeof units[number];

export const fileTypeGuard = (allowedFileTypes: string[], fileType:string | null):boolean => {  
  
  
  if (fileType === null) return false // no file type
  
  if (allowedFileTypes.length === 0) return true // every file type is allowed
  return allowedFileTypes.includes(fileType) // check if file type is in the array of allowed file types
}

export const calculateBytesFromNumberAndUnits = (unitValue: number, unit: UnitTypes):number => {
  const index = units.indexOf(unit)
  if(index === 0) return unitValue
  
  const multiplier = [...Array(index).keys()].reduce((acc, curr) => acc * 1024, 1) 
  return unitValue * multiplier
} // 1048576 bytes in 1 MB

export const shortenByteAndAddUnit = (size: number | undefined):string => {
  let unitIndex = 0, fileSize = size || 0

  
  while (fileSize >= 1024 && ++unitIndex) fileSize = fileSize / 1024 
  return (fileSize.toFixed(fileSize >= 10 || unitIndex < 1 ? 0 : 1) + ' ' + units[unitIndex])
} // 10 MB or 1.2 KB

// unused
export const fileSizeGuard = (maxFileSize: number, sizeUnit: UnitTypes, fileSize: number | undefined):boolean => {
  const maxFileSizeInBytes = calculateBytesFromNumberAndUnits(maxFileSize, sizeUnit)
  return fileSize ? fileSize <= maxFileSizeInBytes : false
} // true / false

export const partitionFilesBySizeLimit = (files: File[], maxSizeInBytes:number) => {
  const filesThatAreWithinLimit = files.filter(file => file.size <= maxSizeInBytes)
  const filesThatAreTooLarge = files.filter(file => file.size > maxSizeInBytes)
  
  return [filesThatAreWithinLimit, filesThatAreTooLarge]
} // File[][]


// getMimeTypes works both ways, it can convert file extensions to mime types and vice versa
export const getMimeTypes = (fileExtensions:string[]) => {
  const mimeTypes: { [key:string]:string } = {
    'key': 'application/x-iwork-keynote-sffkey', // 'application/vnd.apple.keynote' for future use
    'application/x-iwork-keynote-sffkey': 'key', // 'application/vnd.apple.keynote' for future use
    'csv': 'text/csv',
    'text/csv': 'csv',
    'pdf': 'application/pdf',
    'application/pdf': 'pdf',
    'doc': 'application/msword',
    'application/msword': 'doc',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'xls': 'application/vnd.ms-excel',
    'application/vnd.ms-excel': 'xls',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'ppt': 'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint': 'ppt',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  } 
  
  return fileExtensions.map(ext => mimeTypes?.[ext] || '').join(', ')
}