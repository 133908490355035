<template>
  <div
    class="relative flex flex-col w-full"
    @dragenter.prevent="setDragActive" 
    @dragleave.prevent="setDragInactive" 
    @dragover.prevent="setDragActive" 
    @drop.prevent="captureFile"
  >
    <slot />
    <div
      v-if="dragActive"
      class="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-white pointer-events-none "
      :aria-hidden="true"
    >
      <p
        v-if="isTypeAllowed"
        class="flex items-center justify-center w-full h-full p-4 text-lg text-gray-900 bg-white border-2 border-gray-900 border-dashed rounded-md"
      >
        Release to select file
      </p>
      <p
        v-else
        class="flex items-center justify-center w-full h-full p-4 text-lg bg-white border-2 border-dashed rounded-md border-error-900 text-error-700 "
      >
        This filetype is not allowed
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fileTypeGuard } from '@/utils/file'
interface IProps {
  allowedFileTypes?: string[]
  disabled:boolean
}
const props = defineProps<IProps>()
interface IEmit {
  (e: 'update', file: File[]): void
}
const emit = defineEmits<IEmit>()
// ref for visually showing if dragover is active
const dragActive = ref(false)
// ref for visually showing if file type is allowed or not
const isTypeAllowed = ref(true)

const setDragActive = async (event:DragEvent) => {    
  if(props.disabled) return

  if(!event.dataTransfer) return

  dragActive.value = true
  
  let isOneOrMoreFilesOfAllowedType = false

  
  for (let i = 0; i < event.dataTransfer.items.length; i++) {
    // If dropped items aren't files, reject them
    if (event.dataTransfer.items[i].kind === 'file') {
      const fileType = event.dataTransfer.items[i].type
      if(fileType && fileTypeGuard(props.allowedFileTypes || [], fileType)) {
        isOneOrMoreFilesOfAllowedType = true
      }
    }
  }
  
  isTypeAllowed.value = isOneOrMoreFilesOfAllowedType
}

// reset visual feedback for drag and drop
const setDragInactive = () => {
  if(props.disabled) return
  dragActive.value = false
  isTypeAllowed.value = true
}

// handle file drop
const captureFile = (event: DragEvent) => {
  // Remove drag active class
  if(props.disabled) return
  setDragInactive()
  if(!event.dataTransfer) return
  
  const acceptedFiles = []
  for (let i = 0; i < event.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (event.dataTransfer.items[i].kind === 'file') {
          const file = event.dataTransfer.items[i].getAsFile();
          
          if(file && fileTypeGuard(props.allowedFileTypes || [], file.type)) {
            acceptedFiles.push(file)
          }
          // ignore files that are not allowed
      }
  }
  if (acceptedFiles.length > 0) {
    emit('update', acceptedFiles)
  }

// TODO: handle possible errors. 
}

</script>