type SeparatorList = Array<[string, number]>
const defaultSeparatorList:SeparatorList = [['//', 1], ['?',0], ['#',0], ['/',0], ['www.',1]]
const stripUrl = (url:string, separator:string, returnIndex:number ) => url.split(separator)[returnIndex] ??= url.split(separator)[0]

import topLevelDomains from '~/data/topLevelDomains'

export const prettifyUrl = (url:string | null | undefined, listOfSeparatort:SeparatorList = defaultSeparatorList) => {
  if(!url) return ''
  if(url === 'N/A') return ''
  let returnUrl = url
  listOfSeparatort.forEach((separator) => returnUrl = stripUrl(returnUrl, separator[0], separator[1]))
  return returnUrl
}

export type LinkedInSearchFilter = 'schools' | 'companies' | 'all' | 'people'
export const createLinkedInSearchUrl = (filter:LinkedInSearchFilter, query:string) => `https://www.linkedin.com/search/results/${filter}/?keywords=${query}`

export const removeProtocol = (url:string) => url ? url.replace(/(^\w+:|^)\/\//, '') : ''

const extractDomain = (url:string) => {
  const regex = /^[^\s\/?#]+/;
    const match = url.match(regex);
    return match ? match[0] : '';
}

export const isValidTLD = (urlString: string):boolean => {
  try {
      const parts = urlString.split('.');
      if(parts.length < 2) return false
      const extractedTLD = parts[parts.length - 1];
      return topLevelDomains.includes(extractDomain(extractedTLD.toUpperCase()));
    } catch (error) {
      return false;
  }
}

export const addProtocolToUrl = (url: string) => {
  return `https://${url}`
}

export function getLinkedInId(linkedinUrl: string | undefined) {
  if(!linkedinUrl) return ''
  return linkedinUrl.split('/').pop()
}
