<template>
 
    <div>
      <label
        for="file__input"
        class="text-sm font-medium text-gray-700 dark:text-gray-200"
        :class="hideLabel && 'sr-only'"
      >
        File upload
      </label>
      <div class="flex">
        <button
          id="file__input"
          class="flex flex-col items-center w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 form-input rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-4 shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:ring-2  hover:ring-primary-500 dark:focus:ring-primary-400"
          @click="() => dialog.open()"
        >
          <div class="mb-4 text-3xl" v-if="!hideIcon">
            <FadedIcon icon-name="upload" color="gray" />
          </div>
          <span>
            <span class="font-semibold text-primary-700">
              Click to upload file
            </span>
            <span> or drag and drop </span>
          </span>
          <span class="text-xs text-gray-600">
            pdf, pptx or keynote (max 100MB )
          </span>
        </button>
      </div>
    </div>
 
</template>

<script setup lang="ts">
const props = defineProps({
  acceptedFileTypes: {
    type: Array,
    default: () => ["application/pdf"],
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  hideIcon: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  update: [value: File[]];
}>();

const dialog = useFileDialog({
  accept: props.acceptedFileTypes.join(", "), // Set to accept only image files
  multiple: true, // Allow selecting multiple files
});

dialog.onChange((files) => {
  if (!files) return;

  const listOfFiles = [] as File[];
  [...Array(files.length).keys()].forEach((_, index) => {
    listOfFiles.push(files[index]);
  });
  console.log(listOfFiles);

  emit("update", listOfFiles);
  dialog.reset();
});
</script>
